/* global NEWPORTAL */
import news_data from './data';
import NewsSlider from './slider';

// /* eslint-disable no-unused-vars */
// import Carousel from 'bootstrap/js/dist/carousel'; //todo ads 16KB gzipped. maybe use glidf
// import BlazeSlider from 'blaze-slider'
import BlazeSlider from './blaze'
// import 'blaze-slider/dist/blaze.css'
//alternatives:
//gliderjs adds 3KB: https://nickpiscitelli.github.io/Glider.js/
//glidejs adds 8KB: https://glidejs.com/
//blaze just 2KB: https://blaze-slider.dev/

function _setBgUrl (viewPortWidth, el) {
    const bgUrl = viewPortWidth <= 480 && el.dataset.bg_mob ? el.dataset.bg_mob : el.dataset.bg_img;
    if (bgUrl) {
        el.style.backgroundImage = "url('"+bgUrl+"')";
    }
}

export default (function () {
    return {
        selectors: {
            topCarousel: '#carousel_slider_top',
            // bottomCarousel: '#carousel_slider_bottom'
        },
        getOrderedCategories: function(categories) {
            var categoryOrder = NEWPORTAL.news_conf.category_order_importance;

            return categories
            //get categories
                .sort(function(a, b){
                    var aOrderVal = typeof categoryOrder[a.name] !== "undefined" ? categoryOrder[a.name] : 0;
                    var bOrderVal = typeof categoryOrder[b.name] !== "undefined" ? categoryOrder[b.name] : 0;

                    return bOrderVal-aOrderVal;
                });
        },
        getNewsSource: function (carouselId, newsItem) {
            const sources = news_data.sliderArticles[carouselId].sources;

            if (newsItem.source && typeof newsItem.source !== "string") {
                return newsItem.source;
            }

            return sources.filter(value => value.sourceName === newsItem.source)[0];
        },

        handleNewsImgIntersection: function (entries, observer) {
            const viewPortWidth = window.innerWidth;
            entries.map((entry) => {
                if (entry.isIntersecting) {
                    if (entry.target.style.backgroundImage) {
                        // Job done for this item - no need to watch it!
                        observer.unobserve(entry.target);
                        return;
                    }
                    // Item has crossed our observation threshold - load img!
                    _setBgUrl(viewPortWidth, entry.target);
                    // Job done for this item - no need to watch it!
                    observer.unobserve(entry.target);
                }
            });
        },

        loadBgImages: function (newsItems, lazy=false) {
            const observer = new IntersectionObserver(
                this.handleNewsImgIntersection,
                {
                    rootMargin: "50px"
                }
            );

            const viewPortWidth = window.innerWidth;
            newsItems.forEach(item => {
                if (item.style.backgroundImage) {
                    // Job done for this item - no need to watch it!
                    return;
                }
                if (lazy) {
                    observer.observe(item);
                } else {
                    _setBgUrl(viewPortWidth, item);
                }
            });
        },

        loadNextSlideBgImages: function (slideNo) {
            this.loadBgImages(document.querySelectorAll('.carousel-slide-'+(slideNo)+' .main-news'));
            this.loadBgImages(document.querySelectorAll('.carousel-slide-'+(slideNo)+' .header-s, .carousel-slide-'+(slideNo)+' .header-l'), true);
        },

        fillTopSlider: function(newsItems, categories, sources, categoryType, categoryChosen) {
            // Set all data needed for each slider
            categories = this.getOrderedCategories(categories);
            news_data.setFilteredSliderData('carousel_slider_top', newsItems, categories, sources);

            var
                smallItemTopSmallClasses = 'col-6 col-md-4 col-lg-6',
                smallItemTopBigClasses = 'd-none', //d-lg-block col-lg-4 d-xl-none todo looks like not needed as always d-none?
                slideTopSmallNewsClasses = ' col-12 hidden-sm d-md-block d-lg-block d-xl-block col-lg-12',
                slideTopBigNewsClasses = 'visible-xxl-block col-widget-2',
                additionalMainNewsWrapperClasses = '',
                bigItemWrapperClasses = 'col-xxl-6';

            this.topSlider = new NewsSlider(
                this.selectors.topCarousel,
                categories,
                categoryType,
                categoryChosen,
                slideTopSmallNewsClasses,
                slideTopBigNewsClasses,
                smallItemTopSmallClasses,
                smallItemTopBigClasses,
                additionalMainNewsWrapperClasses,
                bigItemWrapperClasses
            );
            var that = this;

            const chunkWithMinSize = (arr, chunkSize, minChunkSize = 0) => {
                const remainder = arr.length % chunkSize;
                const isLastChunkTooSmall = remainder < minChunkSize;
                const totalChunks = isLastChunkTooSmall
                    ? Math.floor(arr.length / chunkSize)
                    : Math.ceil(arr.length / chunkSize);
                return Array.from({ length: totalChunks }, (_, i) => {
                    const chunk = arr.slice(i * chunkSize, i * chunkSize + chunkSize);
                    if (i === totalChunks - 1 && isLastChunkTooSmall)
                        chunk.push(...arr.slice(-remainder));
                    return chunk;
                });
            };

            const chunkedNewsItems = chunkWithMinSize(newsItems, 11, 11);
            // return;
            for (let i = 0; i < chunkedNewsItems.length; i++) {
                var mainNewsItem = chunkedNewsItems[i].shift();
                var smallNewsItems = chunkedNewsItems[i].splice(0, 6);
                var bigNewsItems = chunkedNewsItems[i].splice(0, 4);

                that.topSlider.addSlide(i, mainNewsItem, smallNewsItems, bigNewsItems);

                //render first slide with images immediately
                if (i === 0) {
                    that.topSlider.renderSlide(0);
                    that.loadNextSlideBgImages(0);
                }
            }

            const loader = document.querySelector('.carousel-slide-0 .main-news .sk-circle');
            if (loader) {
                loader.parentNode.removeChild(loader);
            }

            const totalSlides = chunkedNewsItems.length;

            //render and preload images for 2nd slide and last slide
            that.topSlider.renderSlide(1);
            that.topSlider.renderSlide(totalSlides-1);

            that.loadNextSlideBgImages(1);
            that.loadNextSlideBgImages(totalSlides-1);

            const carouselEl = document.querySelector('#newsBsCarousel');

            const el = document.querySelector('.blaze-slider');
            const slider = new BlazeSlider(el, {
                transitionDuration: 500, // (300 is default)
            });
            let prevSlide = 0;
            slider.onSlide((pageIndex) => {
                //todo just use blaze slider event
                carouselEl.dispatchEvent(new CustomEvent('slide.bs.carousel', {
                    detail: {
                        from: prevSlide,
                        to: pageIndex
                    }
                }));

                prevSlide = pageIndex;
            });

            document.querySelector('#newsBsCarousel').addEventListener('click', e => {
                if (e.target.matches('.carousel-control-prev')) {
                    slider.prev(1);
                }
                if (e.target.matches('.carousel-control-next')) {
                    slider.next(1);
                }
            });

            return newsItems;
        },

        //dropdown does not work well in bootstrap5 here so we have our own custom implementation for clicks
        handleMoreCategoriesClick: () => {
            document.addEventListener('click', function (event) {
                const closestMoreNewsEl = event.target.closest('.news-more');
                if (!closestMoreNewsEl) {
                    //find all open categories more dropdowns and close them
                    document.querySelectorAll('.news-more.open').forEach((newsMoreItem) => {
                        newsMoreItem.classList.remove('open');
                    });
                    return;
                }
                closestMoreNewsEl.classList.toggle('open');
            }, false);
        },

        // updates slider when category is clicked
        updateSliderWithCategoryData: function (
            currCategoryType,
            categoryId
        ) {
            var linkTemplate = NEWPORTAL.widget_apis.news_template;
            var that = this;

            news_data.getFeedData(
                linkTemplate.url,
                linkTemplate.fallback_url,
                currCategoryType,
                categoryId,
                function (feedData) {
                    that.fillTopSlider(feedData.articles, feedData.categories, feedData.sources, currCategoryType, categoryId);
                    NEWPORTAL.isCategoryLoaded = true;
                }
            );
        }
    };
}());
