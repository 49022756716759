import sha1 from 'js-sha1';
export { sha1 };

export function prepareSubmit(form) {
    // Some domains like @tvnet.lv does not understand our client side hashing so these domains need to be skipped
    const { imapuser } = form;
    const domainsToIgnore = (inx && inx.params && inx.params.domains_without_pass_hash_login)
        ? inx.params.domains_without_pass_hash_login
        : ['tvnet.lv'];
    let salt = form.salt.value;

    if (imapuser) {
        const [, domain] = imapuser.value.split('@');
        if (domainsToIgnore.includes(domain)) {
            salt = null;
        }
    }

    if (salt) {
        const encryptedPass = sha1(form.pass.value);
        form.pass.value = '';
        form.passhash.value = sha1(salt + encryptedPass);
    }
    return true;
}