/* global NEWPORTAL */
import getWithFallback from "./ajax";

export default (function () {

    let schedule = null;

    const getSchedule = async function () {
        if (!schedule) {
            schedule = fetch(NEWPORTAL.widget_apis.news_schedule.url)
                .then((response) => response.json());
        }
        return schedule;
    };

    return {
        sliderArticles: {
            'carousel_slider_top': {'news': [], 'categories': [], 'sources': [], 'menu_type': ''},
        },

        categoryTop: 21,
        categoryAll: 0,

        // Add newsHash to news item links, so it later could be used in modal window to get full news data
        addDataToNewsLink: function (div, currNews) {
            var obj = div.localName?.toLowerCase() === 'div' ? div.querySelector('a') : div;

            obj.setAttribute('data-news_item_hash', currNews.newsHash);
            obj.dataset.news_item_hash = currNews.newsHash;

            return div;
        },
        getFeedData: function (url, fallbackUrl, categoryType, categoryId, getFeedDataCallback) {
            getSchedule().then((schedule)=> {
                let source = '';
                let now = new Date();
                now.setFullYear(1970);
                now.setMonth(0);
                now.setDate(1);

                schedule.every((entry) => {
                    const from = new Date(entry.fromHour);
                    const to = new Date(entry.toHour);
                    if (from <= now && to >= now) {
                        source = entry.source;
                        return false;
                    }
                    return true;
                });

                const feedUrl = this.getFeedLink(url, categoryType, source, categoryId);
                const feedFallbackUrl = this.getFeedLink(fallbackUrl, categoryType, source, categoryId);
                getWithFallback(feedUrl, feedFallbackUrl, function(data) {
                    getFeedDataCallback(data);
                });
            });
        },
        getFeedLink: function (url, categoryType, sourceId, categoryId) {
            return url.replace('#SOURCE_ID#', sourceId).replace('#CATEGORY_ID#', categoryId);
        },
        getNewsItemData: function (newsHash) {
            var sliderId = 'carousel_slider_top';
            var len = this.sliderArticles[sliderId].news.length;
            for (var i = 0; i < len; i++) {
                if (this.sliderArticles[sliderId].news[i].newsHash === newsHash) {
                    return this.sliderArticles[sliderId].news[i];
                }
            }
            //should not happen but if happens jo js error occur
            return {'newsSource': {}};
        },
        // Set items in item cache so alter this data can be used to open modal window
        setFilteredSliderData: function (carouselOuterWrapperID, filteredNewsItems, categories, sources) {
            var newsItems = filteredNewsItems.slice(0); //clone array

            this.sliderArticles[carouselOuterWrapperID] = {
                'news': newsItems,
                'categories': categories,
                'sources': sources
            };
        }
    };
}());
