export default function (task, delay) {
    delay = delay || 1000;
    task = task || function () {};

    this.timer = null;
    this.stuck = false;

    this.run = function () {
        this.stuck = !task();
    };

    this.postpone = function () {
        this.start();
    };

    this.start = function() {
        this.stop();
        var that = this;
        this.timer = setInterval(function(){that.run();}, delay);
    };

    this.stop = function () {
        clearInterval(this.timer);
    };

    this.run();
    this.start();
}