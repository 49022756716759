export default {
    isVisible: function (id, mS) {
        if (!id) {
            return false;
        }
        var elm = id.id ? id : document.getElementById(id);
        var H, W;
        if (!elm) {
            return false;
        }
        var rect = elm.getBoundingClientRect(),
            vpW = (window.innerWidth||document.documentElement.clientWidth||document.body.clientWidth),
            vpH = (window.innerHeight||document.documentElement.clientHeight||document.body.clientHeight);
        if(rect.bottom<=0 || rect.top>=vpH || rect.right<=0 || rect.left>=vpW)
            return false;   //not visible
        W = rect.width;
        H = rect.height;
        if(rect.top<=0)
            H = rect.bottom;
        if(rect.bottom>vpH)
            H = vpH - rect.top;
        if(rect.left <= 0)
            W = rect.right;
        if(rect.right > vpW)
            W = vpW - rect.left;
        if(typeof(mS)=='undefined') mS = 0.5;

        if(((W*H)/(rect.width*rect.height)) >= mS)
            return true;
        else
            return false;
    },
    reloadIfHttpsAvailable: function () {

        //if already on https, skip this check
        if (window.location.protocol === 'https:') {
            return;
        }

        var prefetchImg = document.querySelectorAll('.prefetch_img');

        //reload page  if prefetch image from https is loaded (it means https is available)
        function prefetchLoaded() {
            window.location = window.location.href.replace(/^http:/, 'https:');
        }

        for(var i = 0; i < prefetchImg.length; i ++) {
            if (prefetchImg[i].complete && prefetchImg[i].naturalWidth > 0) {
                prefetchLoaded();
            } else {
                prefetchImg[i].addEventListener('load', prefetchLoaded);
            }
        }
    },
    dateTimeFormat: function (date) {
        date = new Date(date);
        var minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
        return date.toLocaleDateString() + ' ' + date.getHours() + ':' + minutes;
    },
    visibilityObserver: new function () {

        var visible = {};
        var observable = {};
        var that = this;

        this.isVisible = function (id) {
            return visible[id];
        };

        this.observe = function (el, callback) {
            observable[el.id] = {el: el, callback: callback};
            this.intersectionObserver.observe(el, callback);
        };

        this.unobserve = function (el) {
            delete observable[el.id];
            this.intersectionObserver.unobserve(el);
        };

        this.onIntersection = function (entries/*, observer */) {

            for (var i = 0; i < entries.length; i++) {
                var v = entries[i];
                var callback = observable[v.target.id] ? observable[v.target.id].callback : false;

                if (!callback) {
                    return true;
                }

                visible['#' + v.target.id] = v.isIntersecting;

                callback(v, that);
            }
        };

        this.intersectionObserver = new IntersectionObserver(this.onIntersection, {
            rootMargin: '0px 0px 20px 0px'
        });
    }
};
