export default async (primaryUrl, fallbackUrl, callback) => {
    try {
        const response = await fetch(primaryUrl, {
            method: 'GET',
            mode: primaryUrl.includes('//') ? 'cors' : 'no-cors'
        });
        const data = await response.json();
        // array || <ie8 fallback || >=ie9,chrome...
        if (data.length > 0 || (Object.keys(data).length > 0)) {
            callback(data);
        } else { // Get fallback if zero items
            throw new Error('bad news data provided');
        }
    } catch (err) {
        if (fallbackUrl) {
            try {
                const response2 = await fetch(fallbackUrl, {
                    method: 'GET',
                    mode: fallbackUrl.includes('//') ? 'cors' :  'no-cors'
                });
                const data2 = await response2.json();

                callback(data2);
            } catch (err) {
                console.log(err);
            }
        }
    }
};
