import { getBreakPoints as getBpoints } from '../../../../../vendor/inbox/toolbarbundle/assets/js/utils';

import news from './news/news';
// import news_data from './news/data';
import util from './utils';

// Moving content elements in DOM for different breakpoints
const adjustStyle = (width) => {
    const hasPageTop = document.body.classList.contains('sbv2');
    const bPoints = getBpoints(hasPageTop ? 'sbv2' : undefined);

    if (width < bPoints.sm) {
        // Multiline ellipsis for name days (due to lack of cross-browser support atm)
        // const nameDaysBlock = todayContent.querySelector('.nav-namedays');
        const nameDaysBlock = document.querySelector('.col-today .nav-namedays');
        if (nameDaysBlock) {
            nameDaysBlock.addEventListener("click", () => {
                nameDaysBlock.querySelector('p').style.webkitLineClamp = 'unset';
            });
        }
    }
};

// Check user platform (Allows to target platform and use related fixes with css).
// Fixes font issue for News headers on windows (see _news.sass for css fix on class ".win")
if (navigator?.platform?.match('Win') !== null) {
    var topNews = document.getElementById("carousel_slider_top");
    if (topNews) {
        topNews.className += " win";
    }
}

// This function is called from ADS system when a side banner is loaded
window.updateSideBannerDesign = function () {
    function sideBanner(data) {
        if (typeof(data) === 'undefined') {
            return document.querySelector("body").dataset.is_sb || false;
        }
        document.querySelector("body").dataset.is_sb = data;
    }

    function getLinkData(sliderSelector, dataItem) {
        return document.querySelector(sliderSelector+' .main_news_categories .active .news_category_link').dataset[dataItem];
    }

    window.dispatchEvent(new Event('resize'));

    // Sidebanner already  loaded
    if (sideBanner()) {
        return;
    }

    sideBanner(true);

    setTimeout(() => {
        var currCategoryType = getLinkData('#carousel_slider_top', 'categorytype');
        var currCategoryChosen = getLinkData('#carousel_slider_top', 'filtercategory');

        news.updateSliderWithCategoryData(
            currCategoryType,
            currCategoryChosen
        );
    }, 1000);

    adjustStyle(window.innerWidth);
};

document.addEventListener("DOMContentLoaded", () => {
    adjustStyle(window.innerWidth);

    window.addEventListener('resize', () => {
        adjustStyle(window.innerWidth);
    });

    util.reloadIfHttpsAvailable();
});
