const loadNewsClient = async () => await import(
    /* webpackChunkName: "inbox-news" */
    'news-client' // prod
    // '../../../../../../vendor/inbox/news-client/src/App' // dev
);

const affixTopPromise = (delayMsec) => {
    // Deferred promise for affixTop func
    const affixTop = (() => {
        let resolve;
        const promise = new Promise((res) => {
            resolve = res;
        });
        return { promise, resolve };
    })();

    const oldAffix = window.affixTop;

    window.affixTop = () => {
        oldAffix();
        affixTop.resolve('affix');
    };

    const shortDelay = (mSec) => new Promise((resolve) => {
        setTimeout(resolve, mSec, 'delay');
    });

    return Promise.race([shortDelay(delayMsec), affixTop.promise])
        .then((/* value */) => {
            // console.log(value);
            return loadNewsClient();
        });
};

const getNews = (params) => {
    // console.log(params)
    let hasShown = false;

    return (observer) => {
        if (hasShown) return;

        const getNewsModule = window.affixTop ? affixTopPromise(300) : loadNewsClient();

        getNewsModule
            .then(({ App }) => {
                try {
                    App(params);
                    hasShown = true;
                    observer.unobserve(params.appContainer);
                } catch (err) {
                    console.log(err);
                }
            });
    };
};

export default () => {
    const { inx } = window;
    if (!inx || !inx.newsClient) return;

    const { newsClient } = inx;
    const loadNews = getNews(newsClient);

    const observerCallback = (entries, observer) => {
        // console.log(entries[0]);
        const { isIntersecting } = entries[0];
        isIntersecting && loadNews(observer);
    };

    const intObserver = new IntersectionObserver(observerCallback, {
        rootMargin: '0px 0px 0px 0px',
        threshold: 0.01,
    });
    intObserver.observe(newsClient.appContainer);
};
