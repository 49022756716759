/* global NEWPORTAL */
export default (function () {
    return {
        // On window resize check if viewport is mobile or big screen and show image accordingly
        switchBgImg: function(selector, newsImgUrl) {
            //change image only if news image already loaded before
            if (selector.css('background-image')) {
                selector.css('background-image', 'url(' + newsImgUrl + ')');
            }
        },
        getBgImgUrl: function(currNews, imgWidth, imgHeight) {
            if (!currNews.imageHash) {
                return null;
            }
            var backgroundImageSuffix = '-'+imgWidth+'x'+imgHeight+'.';
            //replace 53bbdddeeff988576ce0074b84500dc5.jpg with 53bbdddeeff988576ce0074b84500dc5-504x300.jpg
            return NEWPORTAL.news_conf.image_cdn + currNews.imageHash.replace(/.([^.]*)$/, backgroundImageSuffix+'$1');
        },
        getBgWithImg: function(currNews, imgWidth, imgHeight) {
            return 'background-image: url('+ this.getBgImgUrl(currNews, imgWidth, imgHeight) +');';
        }
    };
}());
