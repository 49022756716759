/* eslint-disable */
// Define assets path "On The Fly"
__webpack_public_path__ = window.inx.params.project.path.assets;
/* eslint-enable */

import "./main";
import "./main_new";
import showInboxNews from './widget/news-client';
import csrf_protection from "./csrf_protection";
import init from './news/run_news';

const fireUpPortal = () => {
    const { inx, INBOX, NEWPORTAL } = window;
    const { params: { user }} = inx;

    if (user.email && user.email.length > 0) {
        import(
            /* webpackChunkName: "mail-panel" */
            './widget/mail-list'
        ).then(({ default: init }) =>  init(inx, INBOX));
    }

    //set up CSRF protection on page load
    csrf_protection();

    // Show inbox news when enabled
    // alternative to DOMContentLoaded
    document.onreadystatechange = function () {
        if (document.readyState === "interactive") {
            inx.newsClient && showInboxNews();
        }
    }

    /*
    NOTE:
        Global variable "NEWPORTAL" is undefined on the error pages
        This means "NEWPORTAL" CAN NOT be defined as external in webpack.config.js
    */
    if (NEWPORTAL) {
        init(NEWPORTAL);
        // import(
        //     /* webpackChunkName: "module-news" */
        //     './news/run_news'
        // ).then(({ default: init }) => init(NEWPORTAL));
    }
};

fireUpPortal();
