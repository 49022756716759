/* global inxBX, NEWPORTAL */
import inx from 'inx';
import getWithFallback from "./ajax";
import news_image from "./image";
import util from "../utils";

const Modal = function () {
    var self = this;

    this._resetModal = function() {
        var template = document.getElementById('modal-news');
        var newsPic = template.querySelector('.news-pic');
        newsPic.removeAttribute('src');
        newsPic.setAttribute('alt', '');

        var newsBody = template.querySelector('.modal-body');
        // var newsLink = newsBody.querySelector('a h1').innerHTML = '';
        newsBody.querySelector('.news-text').innerHTML = '';

        document.getElementById('article_foot').innerHTML = '';

        document.getElementById('news_relevant_wrapper').style.display = 'none';

        //remove related news items except template itself
        var relatedNewsItems = template.querySelectorAll('.news_relevant_item');
        for (var i = 0; i < relatedNewsItems.length; i++) {
            if (relatedNewsItems[i].id === 'news_relevant_item_template') {
                continue;
            }
            relatedNewsItems[i].parentNode.removeChild(relatedNewsItems[i]);
        }
    };

    var _initAdsAndStats = function (newsItemData) {
        //init bottom ads banner
        //ads conf for news provider
        if (!NEWPORTAL.is_premium) {
            inxBX.placement({id: 'article_foot', consumer: newsItemData.source.consumerId}, 1);
        }
        inxBX.placement({id: self._sliderId+'_kw', consumer: newsItemData.source.consumerId}, 1);

        //modal page-view tracking
        //this check is so on dev we don't have error as we disable analytics tracking on dev
        if (window.ga) {
            window.ga('otherTracker.send', 'event', 'NewsModal', 'Open', newsItemData.source.name,  1);
            window.ga('otherTracker.send', 'event', 'NewsModal', 'Category', newsItemData.category.name,  1);

            if (NEWPORTAL.gemius_news_modal_code) {
                window.gemius_hit(NEWPORTAL.gemius_news_modal_code);
            }
        }
    };

    var _getArticle = function(newsItem) {
        return newsItem.fullArticle ? newsItem.fullArticle : newsItem.shortDescription;
    };

    var _renderRelatedNews = function(relatedNewsItems) {

        var template = document.getElementById('news_relevant_item_template');
        template.style.id = '';

        if (relatedNewsItems.length > 0) {
            document.getElementById('news_relevant_wrapper').style.display = 'block';
        }

        for (var i = 0; i < relatedNewsItems.length; i++) {
            var currNewsItem = relatedNewsItems[i];
            var item = template.cloneNode(true);
            item.id = 'news_relevant_item_template_'+i;
            //for old browser support
            item.querySelector('.news_relevant_item_link').setAttribute('data-relevant_news_no', i);
            //item.querySelector('.news_relevant_item_link').dataset.relevant_news_no = i;
            item.querySelector('.news_relevant_item_title').innerText = currNewsItem.title;
            item.querySelector('.news_relevant_item_img').setAttribute('src', news_image.getBgImgUrl(currNewsItem, 248, 170));
            item.style.display = 'block';
            document.getElementById("news_relevant").appendChild(item);
        }

        //attach click event to re-render news modal for each related news items
        var allRelevantItemLinks = document.querySelectorAll('.news_relevant_item_link');

        for (var n = 0; n < allRelevantItemLinks.length; n++) {
            allRelevantItemLinks[n].addEventListener('click', function(e) {
                e.preventDefault();

                var relNewsNo = this.getAttribute('data-relevant_news_no'); //this.dataset.relevant_news_no

                if (window.ga) { //this check is so on dev we don't have error as we disable analytics tracking on dev
                    window.ga('otherTracker.send', 'event', 'NewsModal', 'OpenRelated', relatedNewsItems[relNewsNo].sourceName,  1);
                }

                var relevantNewsOpenEvent = null;
                if (typeof window.CustomEvent === "function") {
                    relevantNewsOpenEvent = new CustomEvent('relevant_news_open');
                } else { //@todo remove else when ie9-11 not used anymore
                    relevantNewsOpenEvent = document.createEvent('CustomEvent');
                    relevantNewsOpenEvent.initCustomEvent('relevant_news_open', true, true, {});
                }
                document.getElementById('modal-news').dispatchEvent(relevantNewsOpenEvent);

                self.renderModal(relatedNewsItems[relNewsNo], self._sliderId);
                if(Object.prototype.hasOwnProperty.call(relatedNewsItems[relNewsNo], 'newsId')) {
                    self.countNewsClick('modal', 'open', relatedNewsItems[relNewsNo].newsId, true);
                }
            }, false);
        }
    };

    this._sliderId = null; //from which slider modal was open. needed for ads & stats

    this.renderModal = function (newsItemData, sliderId) {
        if (sliderId) { //optional param on first open, no need to pass when rendering related news item on click
            this._sliderId = sliderId;
        }
        this._resetModal();

        var template = document.getElementById('modal-news');

        //set basic info:
        var title = newsItemData.title;
        var img = news_image.getBgImgUrl(newsItemData, 540, 300);

        template.querySelector('.news-pic').setAttribute('src', img);
        template.querySelector('.news-pic').setAttribute('alt', title);

        // var origThis = this;
        getWithFallback(
            NEWPORTAL.widget_apis.news_single.url + newsItemData.newsHash + '.json',
            false,
            function (singleArticleData) {
                document.querySelector('.news-text').innerHTML = _getArticle(singleArticleData);

                //tmp fix source for old news items which still has integer source ids @todo remove after about a month
                if (typeof singleArticleData.source === "undefined" && typeof singleArticleData.newsSource !== "undefined") {
                    singleArticleData.source = singleArticleData.newsSource;
                }

                //set news provider logo
                var newsLogo = template.querySelector('.news-logo img');
                newsLogo.setAttribute('src', NEWPORTAL.news_conf.logo_path + singleArticleData.source.logo);
                newsLogo.setAttribute('alt', singleArticleData.source.name);

                var link = singleArticleData.link;
                var newsLink = template.querySelector('.modal-body > a');
                newsLink.setAttribute('href', link);
                newsLink.setAttribute('data-news_id', singleArticleData.id);
                newsLink.dataset.news_id = singleArticleData.id;
                newsLink.querySelector('h1').innerHTML = title;

                var newsFooterLink = template.querySelector('.modal-footer a');
                newsFooterLink.setAttribute('href', link);

                newsFooterLink.setAttribute("data-source_name", singleArticleData.source.name);
                newsFooterLink.dataset.source_name = singleArticleData.source.name;
                newsFooterLink.setAttribute("data-news_id", singleArticleData.id); //todo probably not needed
                newsFooterLink.dataset.news_id = singleArticleData.id;
                //this not work for ie9, ie10
                //newsFooterLink.dataset.source_name =  singleArticleData.source.name; //for read full article tracking
                template.querySelector('#news_source_name').innerHTML = singleArticleData.source.name;

                //set date
                template.querySelector('.news-date span#m_news_date')
                    .innerHTML =  util.dateTimeFormat(singleArticleData.pubDate);

                template.querySelector('.img-copyright').innerHTML = singleArticleData.imageCopyright;

                _initAdsAndStats(singleArticleData);

                //@todo implement loading icon
            }
        );
    };

    this.countNewsClick = function(source, action, newsId, renderRelated) {
        // var retData = [];
        if (inx.params.news_clicks_active) {
            import(
                /* webpackChunkName: "news-anon-user-id" */
                'fingerprintjs2'
            ).then(({ default: Fingerprint2 }) => {
                new Fingerprint2().get(async function (result) {
                    const data = {
                        source: source,
                        action: action,
                        news_id: newsId,
                        id: result,
                        domain_id: NEWPORTAL.domain_id,
                        language: NEWPORTAL.lng,
                        user_id: NEWPORTAL.user
                    };

                    try {
                        const response = await fetch(NEWPORTAL.news_url + '/click', {
                            method: 'POST',
                            body: new URLSearchParams(data)
                        });
                        const responseData = await response.json();
                        if (renderRelated) {
                            _renderRelatedNews(responseData);
                        }
                    } catch (error) {
                        console.error(error);
                    }
                });
            })
                .catch(() => 'An error occurred while loading the CMP component');
        }
    };
};

export default new Modal();
