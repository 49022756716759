import biscuit from '../../../../../vendor/inbox/toolbarbundle/assets/js/utils/cookie-handler';

//JS based double-submit CSRF protection
//we use it always now because of browser bfcache (back-forward) sometimes caches main page with old token
//https://cheatsheetseries.owasp.org/cheatsheets/Cross-Site_Request_Forgery_Prevention_Cheat_Sheet.html#double-submit-cookie
export default function () {
    // console.log('csrf_protection start');
    const { inx } = window;

    //random token generation
    let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'; //removed !@#$%^&*() chars to avoid urlencoding issues
    let password = '';
    const array = new Uint32Array(chars.length);
    window.crypto.getRandomValues(array);
    for (let i = 0; i < 28; i++) {
        password += chars[array[i] % chars.length];
    }
    const csrfDoubleSubmitToken = password;

    const doubleSubmitCookieData = { name: 'csrf_double_submit', value: csrfDoubleSubmitToken };
    // console.log(doubleSubmitCookieData);

    // console.log(['generated new token', doubleSubmitCookieData]);
    const domainName =  inx.site ? inx.site : 'inbox.lv';
    biscuit.setIt(doubleSubmitCookieData.name, doubleSubmitCookieData.value, null, '/', domainName, true, 'lax');

    //we need to manually synchronize lang (it was done previously in php)
    const htmlLang = document.querySelector('html').getAttribute('lang');
    biscuit.setIt('language', htmlLang, null, '/', domainName, true, 'lax');

    const csrfDoubleSubmitCookieCreated = !!biscuit.getIt(doubleSubmitCookieData.name);
    const csrfDoubleSumitField = document.querySelector('input[name=_token]');
    if (csrfDoubleSubmitCookieCreated && csrfDoubleSumitField) {
        // console.log('token field updated');
        csrfDoubleSumitField.value = csrfDoubleSubmitToken;
    }
}
