/* global bootstrap */
import news from './news';
import news_data from './data';
import news_modal from './modal';
import RepeatedTask from '../repeated_task';

export default config => {
    document.addEventListener("DOMContentLoaded", () => {
        // On load categories are loaded but when clicked until category clicked is rendered this param is false
        config.isCategoryLoaded = true;
        const linkTemplate = config.widget_apis.news_template;
        // get top "category" data and fill top slider
        // @todo replace 1 with currently needed top provider id
        news.handleMoreCategoriesClick();

        const topNewsReload = new RepeatedTask(function () {
            if (document.visibilityState === "hidden") {
                return false; // stuck
            }

            // if (!util.visibilityObserver.isVisible(news.selectors.topCarousel)) {
            //     return false; // stuck
            // }

            if (document.querySelector('#modal-news').style.opacity > 0) {
                return true;
            }

            news_data.getFeedData(
                linkTemplate.url,
                linkTemplate.fallback_url,
                'source',
                news_data.categoryTop,
                function (feedData) {
                    news.fillTopSlider(feedData.articles, feedData.categories, feedData.sources, 'category', news_data.categoryTop);
                }
            );

            return true;
        }, config.news_conf.reload_interval);

        document.addEventListener("visibilitychange", function () {
            if (document.visibilityState === "hidden") {
                return;
            }

            if (topNewsReload.stuck) {
                topNewsReload.run();
            }
        });

        /*
        Switching categories event
        */
        const topSliderEl = document.querySelector('#carousel_slider_top');
        topSliderEl.addEventListener('click', function (e) {
            const tg = e.target;
            if (tg.tagName !== 'A' || tg.closest('.news-select') === null) {
                return;
            }

            //check if previous category click is loaded before allowing new category to be clicked
            if (!config.isCategoryLoaded) {
                setTimeout(function(){
                    tg.click(); //try again
                }, 100);

                return false;
            }

            config.isCategoryLoaded = false; //reset category loaded status

            var currCategoryChosen = tg.dataset.filtercategory;
            var currCategoryType = tg.dataset.categorytype;

            topNewsReload.postpone();

            news.updateSliderWithCategoryData(currCategoryType, currCategoryChosen);

            return false;
        });

        //
        // lazy load images
        //
        const myCarousel = document.getElementById('newsBsCarousel');

        myCarousel.addEventListener('slide.bs.carousel', event => {
            const currentSlide = event.detail.from;
            const nextSlide = event.detail.to;

            //probably can be written simpler?
            const goRight = (nextSlide - currentSlide === 1) || (nextSlide === 0 && nextSlide - currentSlide < -1);

            const totalSlides = news.topSlider.slides.length-1;

            let nextToLoad = 0;
            if (goRight) {
                nextToLoad = event.detail.to + 1;
                if (nextToLoad > totalSlides) {
                    nextToLoad = 0;
                }
            } else {
                nextToLoad = event.detail.to - 1;
                if (nextToLoad < 0) {
                    nextToLoad = totalSlides;
                }
            }

            // console.log({
            //     from: event.detail.from,
            //     to: event.detail.to,
            //     goRight,
            //     nextToLoad
            // });

            topNewsReload.postpone();

            news.topSlider.renderSlide(nextToLoad);
            news.loadNextSlideBgImages(nextToLoad, 0); //preload next main image so news loading feels faster


            // //copy categories from first slide
            const mainNewsCategories = document.querySelector('.carousel-slide-0 .news-select');

            //clicked in the middle of slider- slide not yet rendered
            if (!document.querySelector('.carousel-slide-'+nextSlide+' .news-select')) {
                news.topSlider.renderSlide(nextSlide);
                news.loadNextSlideBgImages(nextSlide, 0);
            }

            document.querySelector('.carousel-slide-'+nextSlide+' .news-select').innerHTML = mainNewsCategories.cloneNode(true).innerHTML;
        });

        const newsModalEl = document.getElementById('modal-news');
        newsModalEl.addEventListener('hidden.bs.modal', () => {
            var modalIframe = newsModalEl.querySelector('iframe');
            if (modalIframe) {
                modalIframe.setAttribute('src', modalIframe.getAttribute('src'));
            }
        });

        document.addEventListener('mouseup', function (e) {
            // This check is so on dev we don't have error as we disable analytics tracking on dev
            const clickedLink = e.target;
            if (window.ga) {
                window.ga('otherTracker.send', 'event', 'NewsModal', 'ClickReadFull', clickedLink.dataset.source_name, 1);
            }
            if(clickedLink.dataset.news_id > 0) {
                news_modal.countNewsClick('modal', 'read_more', clickedLink.dataset.news_id, false);
            }
        }, false);

        document.addEventListener('click', function (e) {
            const clickedLink = e.target;

            if (clickedLink.matches('#read_full_article, .modal-body > a')) {
                e.preventDefault();
                window.open(clickedLink.getAttribute('href'), '_blank');
            }
        }, false);

        function historyListener() {
            const closeEl = document.querySelector('.modal-header .close');
            if (closeEl) {
                closeEl.click();
            }
        }

        //handle click on modal manually

        document.addEventListener('click', function (e) {
            const clickedLink = e.target;
            //renamed from modal to xmodal so bootstrap does not auto-initialize event
            //bootstrap messes swipe and often clicks modal when user swipes it
            if (clickedLink.dataset.bsToggle === 'xmodal' || clickedLink.closest('[data-bs-toggle="xmodal"]') !== null) {
                //pass news item hash from clicked news to modal so on modal open we know which news to show
                const clickedNewsEl = clickedLink.closest('[data-bs-toggle="xmodal"]');
                const modalEl = document.getElementById('modal-news');
                modalEl.dataset.news_item_hash = clickedNewsEl.dataset.news_item_hash;
                //todo set news hash from here
                const myModal = bootstrap.Modal.getOrCreateInstance(document.querySelector('#modal-news'));
                myModal.show();
            }
        });

        //
        // open news modal window
        //
        const modalNewsEl = document.getElementById('modal-news');
        modalNewsEl.addEventListener('show.bs.modal', (e) => {
            //todo only on shown.bs.modal this works :/
            // modalNewsEl.querySelector('.modal-content').scrollTo({top: 0, behavior: 'smooth'});
            //link which was clicked to open modal
            const newsItemHash = modalNewsEl.dataset.news_item_hash ?? e.relatedTarget.dataset.news_item_hash;

            var newsItemData = news_data.getNewsItemData(newsItemHash);
            news_modal.renderModal(newsItemData, 'carousel_slider_top');
            if(newsItemData.id > 0) {
                news_modal.countNewsClick('portal', 'open', newsItemData.id, true);
            }
            topNewsReload.postpone();

            window.history.pushState({}, '', '#modal-news');
        });
        modalNewsEl.addEventListener('shown.bs.modal', () => {
            modalNewsEl.querySelector('.modal-content').scrollTo({top: 0, behavior: 'smooth'});
            window.addEventListener('popstate', historyListener, false);
        });
        modalNewsEl.addEventListener('hidden.bs.modal', () => {
            //https://github.com/twbs/bootstrap/issues/33727
            document.querySelector('.modal-backdrop')?.remove();
            if (window.history.state === '#modal-news') {
                history.go(-1);
            }
            window.removeEventListener('popstate', historyListener);
        });
    });
};
